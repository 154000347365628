import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../../config";
import { useAuth } from "../../useAuth";
import { handleResponse } from "../../../utils/response";
import { toast } from "react-toastify";
import { blueprintKeys, userKeys } from "../../../utils/query-key";
import { SEARCH_BOX_ITEMS, THIRTY_SECONDS } from "../../../utils/constant";
import {
  OrderStatus,
  OrderStatusWithId,
  ReadonlyResponse,
  ReadonlyResponseWithId,
  SearchIdRequest,
} from "../../../utils/types/general_type";
import {
  BlueprintBase,
  Blueprints,
  ReadonlyBlueprintResponse,
  ReadonlyGetBlueprint,
  UpdateBlueprint,
} from "./type";

export function useCreateBlueprint() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/blueprint`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: blueprintKeys.blueprints_by_filter(true, false),
      });
    },
  });
}

export function useUpdateBlueprint() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: BlueprintBase) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/blueprint`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          order_id: data.order_id,
          title: data.title,
          is_temporarily_stored: data.is_temporarily_stored,
          is_ordered: data.is_ordered,
          send_blueprint_images: data.blueprint_images
            .filter((img) => img.id || img.file) // img.idが存在するか、img.fileが存在する場合のみ含む
            .map((img) =>
              img.file
                ? {
                    id: img.id,
                    filename: img.file!.name,
                    filetype: img.file!.type,
                    description: img.description,
                  }
                : (() => {
                    return {
                      id: img.id,
                      description: img.description,
                    };
                  })(),
            ),
        } satisfies UpdateBlueprint),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "アップロード中にエラーが発生しました",
        );
      }
      const res = (await response.json()) as ReadonlyBlueprintResponse;
      if (res.blueprintImages.length > 0) {
        // 各presignedUrlに対して非同期リクエストを並列に実行し、Blob URLを作成
        const uploadPromises = res.blueprintImages.map(async (image, index) => {
          // 各presignedUrlに対応するファイルデータ
          const blueprintImages = data.blueprint_images;
          const fileData = blueprintImages.find(
            (it) => it.file?.name === image.filename,
          )?.file;
          if (fileData) {
            const uploadResponse = await fetch(image.presignedUrl, {
              method: "PUT",
              body: fileData,
            });
            if (!uploadResponse.ok) {
              throw new Error(`アップロードに失敗しました: URL ${index + 1}`);
            }
          }
        });
        // 全てのBlob URLを配列で取得
        await Promise.all(uploadPromises);
      }

      return res;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("設計図面を一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: blueprintKeys.blueprint(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: blueprintKeys.blueprints_by_filter(true, false),
      });
    },
  });
}

export function useGetBlueprint({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: blueprintKeys.blueprint(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/blueprint/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyGetBlueprint>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useUpdateBlueprintByReceiver() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: BlueprintBase) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/blueprint/receiver`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id: data.id,
            blueprint_details: data.blueprint_details,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("設計図面を更新しました");
      await queryClient.invalidateQueries({
        queryKey: blueprintKeys.blueprint_receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.order,
      });
    },
  });
}
export function useGetBlueprintByReceiver(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: blueprintKeys.blueprint_receiver(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/blueprint/receiver/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyGetBlueprint>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useGetBlueprints({
  isTemporarilyStored,
  isOrdered,
}: OrderStatus) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: blueprintKeys.blueprints_by_filter(
      isTemporarilyStored,
      isOrdered,
    ),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/blueprints?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Blueprints>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useSearchBlueprints({
  searchText,
  limit = SEARCH_BOX_ITEMS,
  id,
}: SearchIdRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: blueprintKeys.search(searchText, id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("limit", `${limit}`);
      if (id) {
        params.append("exclude_id", id.toString()); // 現在のIDをリクエストに含める
      }
      const response = await fetch(
        `${config.backendUrl}/api/blueprints/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Blueprints>;
    },
    enabled: !!id,
    staleTime: THIRTY_SECONDS,
  });
}
