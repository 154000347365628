import { Box, Typography } from "@mui/material";
import { useGetImageFromS3 } from "../hooks/S3/useS3ImageHook";
import { Loading } from "./Loading";
import { Error } from "./Error";
import { BlueprintImage } from "../hooks/Blueprint/Blueprint/type";

export const BlueprintImageComponent = ({
  blueprintImage,
}: {
  blueprintImage: BlueprintImage;
}) => {
  const { data, error, isLoading } = useGetImageFromS3(
    blueprintImage.file_path,
  );
  if (isLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box>
      <Box
        component="img"
        src={data}
        alt="Selected"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
      />
      <Typography>
        {blueprintImage.description && blueprintImage.description !== ""
          ? blueprintImage.description
          : "説明なし"}
      </Typography>
    </Box>
  );
};
