import {
  WoodOrders,
  useContractWoodOrder,
  useSearchWoodOffers,
} from "../../../hooks/Wood/useWoodOrderHook";
import { OfferComponent } from "../../common/OfferComponent";

export const WoodOffer = () => {
  return (
    <OfferComponent<WoodOrders>
      useSearchOffers={useSearchWoodOffers}
      useContractOrder={useContractWoodOrder}
    />
  );
};
