import { BlueprintDetail } from "../hooks/Blueprint/Blueprint/type";
import { LogDetail } from "../hooks/Log/Log/type";
import { WoodDetail } from "../hooks/Wood/Wood/type";
import { PickSameProperties } from "./types/general_type";

const defaultRow: PickSameProperties<[WoodDetail, LogDetail, BlueprintDetail]> =
  {
    name: "",
    origin: "",
    species: "",
    grade: "",
    length: 0,
    unit_volume: 0,
    quantity: 0,
    total_volume: 0,
  };

export const defaultWoodRow: WoodDetail = {
  ...defaultRow,
  id: 0,
  section: "",
};

export const defaultLogRow: LogDetail = {
  ...defaultRow,
  top_diameter: 0,
};

export const defaultBlueprintRow: BlueprintDetail = {
  ...defaultRow,
  section: "",
  amount: 0,
};
