import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import { CreateUser, UpdateUserType } from "../utils/schema";
import { userKeys } from "../utils/query-key";
import { Industry } from "../utils/industry";
import {
  FIVE_MINUTES,
  SEARCH_BOX_ITEMS,
  THIRTY_SECONDS,
} from "../utils/constant";
import {
  SearchRequest,
  ReadonlyResponse,
  TimeStamp,
} from "../utils/types/general_type";
import { Wood } from "./Wood/Wood/type";
import { Log } from "./Log/Log/type";
import { GetBlueprint } from "./Blueprint/Blueprint/type";

export interface User extends TimeStamp {
  id: number;
  user_id?: string;
  email: string;
  is_admin: boolean;
  industry?: Industry; // enumを使用
  phone_number?: string;
  company_name?: string;
  admin_password?: string;
  company_image?: string;
  corporate_number?: string;
  postal_code?: string;
  address?: string;
}

export interface UserOrder {
  user: User;
  wood?: Wood;
  log?: Log;
  blueprint?: GetBlueprint;
}

type ReadonlyUser = Readonly<User>;

export type Users = ReadonlyArray<User>;
type UserOrders = ReadonlyArray<UserOrder>;

export function useCreateUser() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: CreateUser) => {
      const { token } = await getAccessToken();

      const response = await fetch(`${config.backendUrl}/api/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
    },
  });
}

export function useUpdateUser() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<UpdateUserType>) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          industry: data.industry,
          phone_number: data.phoneNumber,
          company_name: data.companyName,
          company_image: data.companyImage,
          corporate_number: data.corporateNumber,
          postal_code: data.postalCode,
          address: data.address,
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: userKeys.user,
      });
    },
  });
}

export function useGetUser() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.user,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyUser>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

// export function useGetUsers() {
//   const { getAccessToken } = useAuth();
//   return useQuery({
//     queryKey: userKeys.users,
//     queryFn: async () => {
//       const { token } = await getAccessToken();
//       const response = await fetch(`${config.backendUrl}/api/users`, {
//         method: "GET",
//         headers: {
//           Authorization: `${token}`,
//         },
//       });
//       return handleResponse(response) as Promise<Users>;
//     },
//   });
// }

export function useSearchUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.search(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
  });
}

export function useSearchMessageRoomUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchMessageRoom(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/message_room/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchWoodUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchWood(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/wood/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchLogUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchLog(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/log/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchBlueprintUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchBlueprint(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/blueprint/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useGetOrderUsers() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.order,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/users/order`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<UserOrders>;
    },
    staleTime: THIRTY_SECONDS,
  });
}
