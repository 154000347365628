import { Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { BlackButton } from "../../../uis/Button/Button";
import { useForm, useFieldArray } from "react-hook-form";
import {
  useGetWoodByReceiver,
  useUpdateWoodByReceiver,
} from "../../../hooks/Wood/Wood/useWoodHook";
import { useParams } from "react-router-dom";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { QuotationTable } from "./quotation_table";
import { Wood } from "../../../hooks/Wood/Wood/type";

export const Quotation = () => {
  const { mutateAsync } = useUpdateWoodByReceiver();
  const { wood_id } = useParams<{ wood_id: string }>();
  const { data, error, isPending } = useGetWoodByReceiver(Number(wood_id));
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<Wood>();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: "wood_details",
  });

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      if (data.wood_details && data.wood_details.length > 0) {
        replace(data.wood_details);
      } else {
        replace([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (data: Wood) => {
    data.id = Number(wood_id);
    try {
      await mutateAsync(data);
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", paddingTop: "10%" }}
      >
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
          disabled={true}
        />
        <QuotationTable
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
        />
        <BlackButton
          isSmallScreen
          text={"金額を入力"}
          type="submit"
          disabled={isSubmitting}
        />
      </form>
    </Box>
  );
};
