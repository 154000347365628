import { Button, Typography } from "@mui/material";
import { OrderStatus, ReadonlyResponse } from "../../utils/types/general_type";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { ColumnDefinitionForItemList, ItemList } from "../../uis/Item/ItemList";
import { UseMutationResult } from "@tanstack/react-query";
import { WoodOrder } from "../../hooks/Wood/useWoodOrderHook";
import { LogOrder } from "../../hooks/Log/useLogOrderHook";
import { DetailMap, ItemMap, ItemType } from "../../utils/types/item_type";

type OrderMap<K extends ItemType> = K extends "wood"
  ? WoodOrder
  : K extends "log"
    ? LogOrder
    : never;

interface OrderHistoryProps<K extends ItemType> {
  itemType: K;
  useGetItems: (params: OrderStatus) => {
    data: ReadonlyArray<ItemMap[K]> | undefined;
    error: Error | null;
    isPending: boolean;
  };
  useCancelOrder: () => UseMutationResult<
    ReadonlyResponse,
    Error,
    number,
    unknown
  >;
  columns: ReadonlyArray<ColumnDefinitionForItemList<DetailMap<K>>>;
  renderTitle?: (item: ItemMap[K]) => React.ReactNode; // 追加
}

export const OrderHistory = <K extends ItemType>({
  itemType,
  useGetItems,
  useCancelOrder,
  columns,
  renderTitle,
}: OrderHistoryProps<K>) => {
  const { data, error, isPending } = useGetItems({
    isTemporarilyStored: false,
    isOrdered: true,
  });

  const { mutateAsync } = useCancelOrder();

  const handleCancelOrder = async (itemId: number) => {
    try {
      await mutateAsync(itemId);
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) return <Loading />;
  if (error) return <Error message={error.message} />;

  // 詳細を取得する関数
  const getDetails = (item: ItemMap[K]): ReadonlyArray<DetailMap<K>> => {
    return item[`${itemType}_details` as keyof ItemMap[K]] as ReadonlyArray<
      DetailMap<K>
    >;
  };

  // 注文アクションをレンダリングする関数
  const renderItemActions = (item: ItemMap[K]) => {
    const orders = item[
      `${itemType}_orders` as keyof ItemMap[K]
    ] as ReadonlyArray<OrderMap<K>>;

    if (orders.length > 0) {
      const is_all_active = orders.every((it) => it.is_active);
      // 注文が契約状態ではない場合は注文を取り消すことができる
      if (is_all_active) {
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleCancelOrder(item.id)}
          >
            注文を取り消す
          </Button>
        );
      } else {
        return <Typography>受注済み</Typography>;
      }
    } else {
      return <Typography>注文情報なし</Typography>;
    }
  };

  return (
    <ItemList<ItemMap[K], DetailMap<K>>
      data={data ?? []}
      getDetails={getDetails}
      titleField="title"
      columns={columns}
      renderItemActions={renderItemActions}
      renderTitle={renderTitle}
    />
  );
};
