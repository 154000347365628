import { TemporallyStored } from "../../common/TemporallyStored";
import { useGetWoods } from "../../../hooks/Wood/Wood/useWoodHook";
import { woodColumnsForItem } from "../../../utils/columns";

export const WoodTemporallyStored = () => {
  return (
    <TemporallyStored<"wood">
      itemType="wood"
      useGetItems={useGetWoods}
      columns={woodColumnsForItem}
    />
  );
};
