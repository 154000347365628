import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute";
import Sidebar from "../../uis/Bars/Sidebar";
import { Header } from "../../uis/Bars/Header";

export const HomeLayout = () => {
  const location = useLocation();
  return (
    <PrivateRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={true} />
        <Box
          sx={{
            width: "80%",
          }}
        >
          <Header
            title={location.pathname.includes("order") ? "注文" : "ホーム"}
          />
          <Outlet />
        </Box>
      </Box>
    </PrivateRoute>
  );
};
