import { TabbedLayout } from "./TabbedLayout";

export const messageTabs = [
  { label: "メッセージルーム", path: "/talk/message_rooms" },
  { label: "受注成立のメッセージルーム", path: "/talk/order_message_rooms" },
] as const;

export const MessageRoomsLayout = () => {
  return <TabbedLayout tabs={messageTabs} />;
};
