import { useQuery } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { User } from "./useUserHook";
import { orderMessageRoomKeys } from "../utils/query-key";
import { MessageBase } from "./Message/useMessageHook";
import { MessageRoomBase } from "./Message/useMessageRoomHook";
import { SearchRequest } from "../utils/types/general_type";
import { FIVE_MINUTES, SEARCH_BOX_ITEMS } from "../utils/constant";
import { LogOrder } from "./Log/useLogOrderHook";
import { WoodOrder } from "./Wood/useWoodOrderHook";
import { BlueprintOrder } from "./Blueprint/useBlueprintOrderHook";
import { ItemType, OrderMessageRoomType } from "../utils/types/item_type";

export interface LogMessage extends MessageBase {
  log_order_message_room_id: number;
}
export interface WoodMessage extends MessageBase {
  wood_order_message_room_id: number;
}

export interface BlueprintMessage extends MessageBase {
  blueprint_order_message_room_id: number;
}

interface ItemMessageMap {
  log: LogMessage;
  wood: WoodMessage;
  blueprint: BlueprintMessage;
}

export interface ItemOrderMessageRoomMap {
  log: LogOrderMessageRoom;
  wood: WoodOrderMessageRoom;
  blueprint: BlueprintOrderMessageRoom;
}

type ItemMessage<T extends ItemType> = ItemMessageMap[T];

interface BaseOrderMessageRoom<T extends ItemType> extends MessageRoomBase {
  order_id: string;
  receiver_id: string;
  messages: ItemMessage<T>[];
  order: User;
  receiver: User;
}

// 特定の注文メッセージルームインターフェース
export interface LogOrderMessageRoom extends BaseOrderMessageRoom<"log"> {
  log_order: LogOrder;
}

export interface WoodOrderMessageRoom extends BaseOrderMessageRoom<"wood"> {
  wood_order: WoodOrder;
}

export interface BlueprintOrderMessageRoom
  extends BaseOrderMessageRoom<"blueprint"> {
  blueprint_order: BlueprintOrder;
}

export interface OrderMessageRoomResponse {
  id: number;
  user: User;
  message?: string;
  title: string;
  unreadCount: number;
  type: ItemType;
}

export type ReadonlyOrderMessageRoom<T extends ItemType> = Readonly<
  ItemOrderMessageRoomMap[T]
>;

export function useGetOrderMessageRoom({
  messageRoomId,
  type,
}: OrderMessageRoomType) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderMessageRoomKeys.orderMessageRoom(messageRoomId),
    queryFn: async () => {
      const { token } = await getAccessToken();
      if (!type) {
        throw new Error("Type is required");
      }
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message_room/${messageRoomId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        ReadonlyOrderMessageRoom<typeof type>
      >;
    },
    enabled: !!type,
  });
}

export function useSearchOrderMessageRooms({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderMessageRoomKeys.search(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/order_message_rooms/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        ReadonlyArray<OrderMessageRoomResponse>
      >;
    },
    staleTime: FIVE_MINUTES,
  });
}
