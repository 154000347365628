import { ProtectedRoute } from "./ProtectedRoute";
import { BlueprintUsers } from "../utils/industry";

export const BlueprintRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedRoute
      requiredIndustries={BlueprintUsers}
      industryErrorMessage="設計図面に関する権限が存在しません。工務店のユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
