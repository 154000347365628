import { Box, Typography, Paper, Chip, Divider, Grid } from "@mui/material";
import { Documents, Document } from "../../hooks/useDocumentHook";
import { Loading } from "../../uis/Loading";
import { Error as CustomError } from "../../uis/Error";
import { AcceptedCategories, FileDownload } from "../../uis/File/FileDownload";

interface DocumentListProps {
  category: AcceptedCategories;
  useGetDocuments: () => {
    data: Documents | undefined;
    error: Error | null;
    isPending: boolean;
  };
}

const getTitle = (document: Document): string => {
  switch (document.type) {
    case "wood":
      return document.wood?.title || "無題の木材";
    case "log":
      return document.log?.title || "無題の丸太";
    case "blueprint":
      return document.blueprint?.title || "無題の設計図";
    default:
      return "不明なタイプ";
  }
};

const getItemTypeLabel = (type: string): string => {
  switch (type) {
    case "wood":
      return "木材";
    case "log":
      return "丸太";
    case "blueprint":
      return "設計図";
    default:
      return "不明";
  }
};

export const DocumentList = ({
  category,
  useGetDocuments,
}: DocumentListProps) => {
  const { data, error, isPending } = useGetDocuments();

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <CustomError message={error?.message} />;
  }

  return (
    <Box sx={{ width: "80%", margin: "0 auto", padding: "2rem" }}>
      {data && data.length > 0 ? (
        data.map((document, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{ padding: "1.5rem", marginBottom: "1.5rem" }}
          >
            {/* タイトルとアイテムタイプの表示 */}
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="h5" component="h2" gutterBottom>
                {getTitle(document)}
              </Typography>
              <Chip
                label={getItemTypeLabel(document.type)}
                color="primary"
                size="small"
              />
            </Box>

            <Divider sx={{ marginBottom: "1rem" }} />

            {/* 受注状況と契約状況の表示 */}
            <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
              {document.is_received && (
                <Grid item>
                  <Chip label="受注済み" color="success" />
                </Grid>
              )}
              {document.is_contract_signed && (
                <Grid item>
                  <Chip label="契約済み" color="secondary" />
                </Grid>
              )}
              {document.is_active && (
                <Grid item>
                  <Chip label="手続き中" color="info" />
                </Grid>
              )}
            </Grid>

            <Divider sx={{ marginBottom: "1rem" }} />

            {/* 発注者と受注者の表示 */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" component="p">
                  <strong>発注者:</strong>{" "}
                  {document.order.company_name || "不明"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" component="p">
                  <strong>受注者:</strong>{" "}
                  {document.receiver.company_name || "不明"}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: "1rem" }} />

            <Grid container spacing={2}>
              <FileDownload
                file={{
                  id: document.document_id,
                  itemType: document.type,
                }}
                category={category}
              />
            </Grid>
          </Paper>
        ))
      ) : (
        <Typography variant="h6" component="p" align="center">
          表示するドキュメントがありません。
        </Typography>
      )}
    </Box>
  );
};
