import { OrderHistory } from "../../common/OrderHistory";
import { useGetBlueprints } from "../../../hooks/Blueprint/Blueprint/useBlueprintHook";
import { useCancelBlueprintOrder } from "../../../hooks/Blueprint/useBlueprintOrderHook";
import { blueprintColumnsForItem } from "../../../utils/columns";

export const BlueprintOrderHistory = () => {
  return (
    <OrderHistory<"blueprint">
      itemType="blueprint"
      useGetItems={useGetBlueprints}
      useCancelOrder={useCancelBlueprintOrder}
      columns={blueprintColumnsForItem}
    />
  );
};
