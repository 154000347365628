import { Error } from "../../uis/Error";
import { ColumnDefinitionForItemList, ItemList } from "../../uis/Item/ItemList";
import { Loading } from "../../uis/Loading";
import { OrderStatus } from "../../utils/types/general_type";
import { DetailMap, ItemMap, ItemType } from "../../utils/types/item_type";

interface TemporallyStoredProps<K extends ItemType> {
  itemType: K;
  useGetItems: (params: OrderStatus) => {
    data: ReadonlyArray<ItemMap[K]> | undefined;
    error: Error | null;
    isPending: boolean;
  };
  columns: ReadonlyArray<ColumnDefinitionForItemList<DetailMap<K>>>;
}

export const TemporallyStored = <K extends ItemType>({
  itemType,
  useGetItems,
  columns,
}: TemporallyStoredProps<K>) => {
  const { data, error, isPending } = useGetItems({
    isTemporarilyStored: true,
    isOrdered: false,
  });

  if (isPending) return <Loading />;
  if (error) return <Error message={error.message} />;

  const getDetails = (item: ItemMap[K]): ReadonlyArray<DetailMap<K>> => {
    return item[`${itemType}_details` as keyof ItemMap[K]] as ReadonlyArray<
      DetailMap<K>
    >;
  };

  return (
    <ItemList<ItemMap[K], DetailMap<K>>
      data={data ?? []}
      getDetails={getDetails}
      titleField="title"
      columns={columns}
    />
  );
};
