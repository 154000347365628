import { useMutation, useQuery } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { s3Keys } from "../../utils/query-key";
import { TEN_MINUTES } from "../../utils/constant";
import { getFileExtension } from "../../utils/file";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export function useGetImageFromS3(image_path?: string) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: s3Keys.image(image_path),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (image_path) {
        params.append("image_path", image_path);
      }

      const response = await fetch(
        `${config.backendUrl}/api/get_image?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("署名付きURLの取得中にエラーが発生しました");
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      return objectURL; // Blob URLを返す
    },
    enabled: !!image_path, // data.pathが存在する場合のみクエリを有効にする
    staleTime: TEN_MINUTES,
    gcTime: TEN_MINUTES,
  });
}

interface DownloadImagesRequest {
  id: number;
  category: "blueprint";
}

interface DownloadImagesResponse {
  urls: string[];
}

export function useDownloadImages() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (req: DownloadImagesRequest) => {
      const { token } = await getAccessToken();

      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(`${config.backendUrl}/api/images`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(req),
      });

      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }

      const { urls } =
        (await presignedResponse.json()) as DownloadImagesResponse;

      if (urls.length > 0) {
        const zip = new JSZip();
        const folder = zip.folder("images");

        // 画像を取得してZIPに追加
        const fetchPromises = urls.map(async (url, index) => {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`画像の取得に失敗しました: URL ${index + 1}`);
          }
          const blob = await response.blob();
          folder?.file(`image_${index + 1}${getFileExtension(url)}`, blob);
        });

        await Promise.all(fetchPromises);

        // ZIPファイルを生成してダウンロード
        const zipBlob = await zip.generateAsync({ type: "blob" });
        saveAs(zipBlob, "images.zip");
      }
    },
  });
}
