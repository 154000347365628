import { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  useMediaQuery,
  ListItemText,
  useTheme,
  Button,
} from "@mui/material";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { useGetOrderUsers, UserOrder } from "../../hooks/useUserHook";
import Avatar from "../../uis/Avatar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { useReceiveOrder } from "../../hooks/useOrderHook";
import { ItemType } from "../../utils/types/item_type";
import { useDownloadImages } from "../../hooks/S3/useS3ImageHook";

export const RequestList = () => {
  const { data, error, isPending } = useGetOrderUsers();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { mutateAsync: mutateReceiveOrderAsync } = useReceiveOrder();
  const { mutateAsync: mutateDownloadAsync } = useDownloadImages();

  // 各注文ごとのdisabled状態を管理するためのステート
  const [disabledOrders, setDisabledOrders] = useState<Record<string, boolean>>(
    {},
  );
  const [order, setOrder] = useState<UserOrder | null>(null);

  const handleReceive = async (
    key: string, // 一意のキーを追加
    userId?: string,
    id?: number,
    type?: ItemType,
  ) => {
    // 現在の注文をdisabledに設定
    setDisabledOrders((prev) => ({ ...prev, [key]: true }));

    // ユーザーIDの確認
    if (!userId) {
      toast.error("ユーザーが存在しません");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    if (!id || !type) {
      toast.error("エラーが発生しました。");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    try {
      await mutateReceiveOrderAsync({
        id, // 必ず number 型
        type: type, // プロパティ名 'type' を追加
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
    }
  };

  const handleDownload = async (id: number) => {
    try {
      await mutateDownloadAsync({ id, category: "blueprint" });
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message || "エラーが発生しました。"} />;
  }

  // dataが存在しない場合の処理
  if (!data || data.length === 0) {
    return (
      <Typography variant="h6" color="textSecondary">
        データが存在しません。
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: "80%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data.map((order, index) => {
          // 一意のキーを生成
          const key = order.wood
            ? `wood-${order.wood.id}`
            : order.log
              ? `log-${order.log.id}`
              : `order-${index}`; // 予備のキー

          const totalAmount = order.wood
            ? order.wood.total_amount
            : order.log
              ? order.log.total_amount
              : order.blueprint
                ? order.blueprint.total_amount
                : null;

          return (
            <ListItem key={key} alignItems="flex-start">
              <ListItemAvatar>
                {/* Avatarコンポーネントを使用 */}
                <Avatar user={order.user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <ListItemText
                primary={order.user.company_name || "会社名がありません"} // 名前表示
                secondary={
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {order.wood?.title ||
                        order.log?.title ||
                        order.blueprint?.title ||
                        "タイトルが存在しません"}{" "}
                      {/* メッセージの内容 */}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      見積もり金額（合計）: ¥
                      {totalAmount && totalAmount !== null
                        ? totalAmount.toLocaleString()
                        : "データが存在しません"}
                    </Typography>

                    <Box sx={{ mt: 1 }}>
                      {(order.log?.id ||
                        (Array.isArray(order.wood?.wood_details) &&
                          order.wood?.wood_details.every(
                            (wood_detail) => wood_detail.amount !== null,
                          )) ||
                        (order.blueprint?.blueprint_details &&
                          order.blueprint?.blueprint_details.length > 0 &&
                          order.blueprint?.blueprint_details.every(
                            (blueprintDetail) =>
                              Object.values(blueprintDetail).every(
                                (value) =>
                                  value !== undefined && value !== null,
                              ),
                          ))) && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={() =>
                            handleReceive(
                              key, // 一意のキーを渡す
                              order.user.user_id,
                              order.wood
                                ? order.wood.id
                                : order.log
                                  ? order.log.id
                                  : order.blueprint
                                    ? order.blueprint.id
                                    : undefined,
                              order.wood
                                ? "wood"
                                : order.log
                                  ? "log"
                                  : order.blueprint
                                    ? "blueprint"
                                    : undefined,
                            )
                          }
                          disabled={disabledOrders[key] || false}
                        >
                          注文を受注する
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => setOrder(order)}
                      >
                        詳細を確認する
                      </Button>
                      {order.wood?.id && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={() =>
                            navigate(`/order/quotation/${order.wood?.id}`)
                          }
                        >
                          見積もり金額を作成する
                        </Button>
                      )}
                      {order.blueprint?.id && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() =>
                              navigate(
                                `/order/create_blueprint_detail_list/${order.blueprint?.id}`,
                              )
                            }
                          >
                            木材リストを作成する
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => handleDownload(order.blueprint!.id)}
                          >
                            設計図面をダウンロードする
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          );
        })}
      </List>
      {order !== null && (
        <OrderDetailModal
          order={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
