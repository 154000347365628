import { Box, Button } from "@mui/material";
import { useDownloadFromS3 } from "../../hooks/S3/useSignedURLHook";
import { toast } from "react-toastify";
import { FileReference } from "../../utils/types/item_type";

export type AcceptedCategories =
  | "quotation"
  | "order_form"
  | "delivery_note"
  | "invoice";

interface FileUploadProps {
  file: FileReference;
  category: AcceptedCategories;
}

const categoryLabels: Record<AcceptedCategories, string> = {
  quotation: "見積書",
  order_form: "注文書",
  delivery_note: "納品書",
  invoice: "請求書", // 追加
};

export const FileDownload: React.FC<FileUploadProps> = ({ file, category }) => {
  const { mutateAsync, isPending } = useDownloadFromS3();

  const handleClick = async (filetype: "xlsx" | "pdf") => {
    try {
      if (!file.id || !file.itemType) {
        toast.error("ファイルが存在しません");
        return;
      }
      const res = await mutateAsync({
        filetype,
        id: file.id,
        category: `${file.itemType}_${category}`,
      });
      const link = document.createElement("a");
      link.href = res;
      link.download = `${category}.${filetype}`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
      <Button
        onClick={() => handleClick("xlsx")}
        disabled={isPending}
        sx={{ mb: 1 }}
      >
        {categoryLabels[category]}をダウンロードする(Excel)
      </Button>
      <Button onClick={() => handleClick("pdf")} disabled={isPending}>
        {categoryLabels[category]}をダウンロードする(PDF)
      </Button>
    </Box>
  );
};
