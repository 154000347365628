import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "../../uis/Bars/Header";
import { AdminRoute } from "../AdminRoute";

export const AdminLayout = () => {
  return (
    <AdminRoute>
      <Header title={""} />
      <Box sx={{ width: "100%", paddingBottom: "18%" }}>
        <Outlet />
      </Box>
    </AdminRoute>
  );
};
