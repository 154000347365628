import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import { ReadonlyResponse } from "../utils/types/general_type";
import {
  documentKeys,
  orderMessageRoomKeys,
  userKeys,
} from "../utils/query-key";
import { ItemType } from "../utils/types/item_type";

interface ReceiveOrderRequest {
  id: number;
  type: ItemType;
}

export function useReceiveOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, type }: ReceiveOrderRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(`${config.backendUrl}/api/${type}_order`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ id }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: userKeys.order,
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.quotations,
      });
      toast.success(
        `${variables.type === "log" ? "丸太" : variables.type === "wood" ? "木材" : "設計図"}リストを受注しました`,
      );
    },
  });
}

interface IssueRequest {
  messageRoomId: number;
  type?: ItemType;
}

export function useDeliveryNoteOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ messageRoomId, type }: IssueRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/${type}_order/delivery_note`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ messageRoomId }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(
          variables.messageRoomId,
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.deliveryNotes,
      });
      toast.success(
        `${variables.type === "log" ? "丸太" : variables.type === "wood" ? "木材" : "設計図"}の納品書を発行しました`,
      );
    },
  });
}
export function useInvoiceOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ messageRoomId, type }: IssueRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/${type}_order/invoice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ messageRoomId }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(
          variables.messageRoomId,
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.invoices,
      });
      toast.success(
        `${variables.type === "log" ? "丸太" : variables.type === "wood" ? "木材" : "設計図"}の請求書を発行しました`,
      );
    },
  });
}
