import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { documentKeys, woodKeys, woodOrderKeys } from "../../utils/query-key";
import {
  ReadonlyResponse,
  ReadonlyResponseWithId,
  SearchRequest,
  TimeStamp,
} from "../../utils/types/general_type";
import { User } from "../useUserHook";
import { FIVE_MINUTES, SEARCH_BOX_ITEMS } from "../../utils/constant";
import { Wood } from "./Wood/type";
import { ItemContractRequest } from "../../utils/types/item_type";

export interface WoodOrder extends TimeStamp {
  id: number;
  wood_id: number;
  receiver_id?: string;
  is_active: boolean;
  is_contract_signed: boolean;
  is_received: boolean;
  wood: Wood;
  user: User;
  wood_quotation?: WoodQuotation;
  wood_order_form?: WoodOrderForm;
  wood_delivery_note?: WoodDeliveryNote;
  wood_invoice?: WoodInvoice;
}

interface WoodQuotation {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface WoodOrderForm {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface WoodDeliveryNote {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface WoodInvoice {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

export interface CreateWoodOrder {
  woodId: number;
  isAll: boolean;
  userIds: string[];
}

export type WoodOrders = ReadonlyArray<WoodOrder>;

export function useCreateWoodOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateWoodOrder) => {
      const { token } = await getAccessToken();
      // userIdsが不要な場合はnullにする
      const userIds = data.isAll ? undefined : data.userIds;

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          wood_id: data.woodId,
          is_all: data.isAll,
          user_ids: userIds, // userIdsがundefinedなら送信しない
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: woodKeys.woods_by_filter(false, true),
      });
    },
  });
}

export function useContractWoodOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: ItemContractRequest) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/wood_order/contract`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_id: req.orderId,
            wood_id: req.itemId,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: woodOrderKeys.search(""),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.orderForms,
      });
    },
  });
}

export function useSearchWoodOffers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodOrderKeys.search(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/wood_orders/user/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<WoodOrders>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useCancelWoodOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (woodId: number) => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/wood_order`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ wood_id: woodId }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: woodKeys.woods_by_filter(false, true),
      });
    },
  });
}
