import { useQuery } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { documentKeys } from "../utils/query-key";
import { ItemType } from "../utils/types/item_type";
import { Wood } from "./Wood/Wood/type";
import { Log } from "./Log/Log/type";
import { BlueprintBase } from "./Blueprint/Blueprint/type";
import { User } from "./useUserHook";
import { THIRTY_SECONDS } from "../utils/constant";

export interface Document {
  type: ItemType;
  wood?: Wood;
  log?: Log;
  blueprint?: BlueprintBase;
  document_id: number;
  is_received: boolean;
  is_contract_signed: boolean;
  is_active: boolean;
  order: User;
  receiver: User;
}

export type Documents = ReadonlyArray<Document>;

export function useGetQuotations() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.quotations,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/quotations`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<Documents>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetOrderForms() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.orderForms,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/order_forms`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<Documents>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetDeliveryNotes() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.deliveryNotes,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/delivery_notes`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<Documents>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetInvoices() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.invoices,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/invoices`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<Documents>;
    },
    staleTime: THIRTY_SECONDS,
  });
}
