import { Log } from "../../../../hooks/Log/Log/type";
import { TableComponent, TableComponentProps } from "../../../../uis/Table";
import { logColumnsForTable } from "../../../../utils/columns";
import { defaultLogRow } from "../../../../utils/rows";

export const LogTable = ({
  register,
  append,
  fields,
  errors,
  remove,
}: TableComponentProps<Log, "log_details">) => {
  return (
    <TableComponent<Log, "log_details">
      register={register}
      append={append}
      fields={fields}
      errors={errors}
      remove={remove}
      fieldArrayName="log_details"
      columns={logColumnsForTable}
      defaultRow={defaultLogRow}
    />
  );
};
