import { Box, Button } from "@mui/material";
import {
  useDeliveryNoteOrder,
  useInvoiceOrder,
} from "../../hooks/useOrderHook";
import { useParams } from "react-router-dom";
import { ItemType } from "../../utils/types/item_type";

interface IssueButtonsProps {
  type?: ItemType;
  isDeliveryNoteButtonShown: boolean;
  isInvoiceButtonShown: boolean;
}

export const IssueButtons = ({
  type,
  isDeliveryNoteButtonShown,
  isInvoiceButtonShown,
}: IssueButtonsProps) => {
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const { mutateAsync, isPending } = useDeliveryNoteOrder();
  const { mutateAsync: mutateInvoiceAsync, isPending: isInvoicePending } =
    useInvoiceOrder();
  const handleInvoiceClick = async () => {
    try {
      await mutateInvoiceAsync({
        messageRoomId: Number(order_message_room_id),
        type,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeliveryNoteClick = async () => {
    try {
      await mutateAsync({
        messageRoomId: Number(order_message_room_id),
        type,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        bgcolor: "background.paper",
        p: 2,
        borderTop: "1px solid #ccc",
        position: "sticky",
        bottom: 0,
      }}
    >
      {isDeliveryNoteButtonShown && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDeliveryNoteClick}
          disabled={isPending}
        >
          納品書発行
        </Button>
      )}
      {isInvoiceButtonShown && (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleInvoiceClick}
          disabled={isInvoicePending}
        >
          請求書発行
        </Button>
      )}
    </Box>
  );
};
