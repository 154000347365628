import { Box, Typography, TextField, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { SearchBox } from "../../../uis/TextField/SearchBox";
import { BlackButton, WhiteButton } from "../../../uis/Button/Button";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import {
  useGetWood,
  useUpdateWood,
  useSearchWoods,
} from "../../../hooks/Wood/Wood/useWoodHook";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/S3/useSignedURLHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { WoodTable } from "./wood_table";
import { Wood } from "../../../hooks/Wood/Wood/type";

export const InputWoodStock = () => {
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const { mutateAsync } = useUpdateWood();
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // CSVおよびExcelに対応するための名前変更
  const { wood_id } = useParams<{ wood_id: string }>(); // URLからwood_idを取得
  const { data, error, isPending } = useGetWood({
    id: Number(wood_id),
    isOrdered: false,
    isTemporarilyStored: true,
  });
  const {
    data: filePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: data?.file_path,
  });
  const { data: woods, error: searchError } = useSearchWoods({
    searchText,
    id: Number(wood_id),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
    setValue,
    control,
  } = useForm<Wood>();
  const navigate = useNavigate();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: "wood_details",
  });

  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      if (data.wood_details && data.wood_details.length > 0) {
        replace(data.wood_details); // 既存のwood_detailsをフィールドに設定
      } else {
        replace([]); // wood_detailsが空の場合は空の配列を設定
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSaveTemporarily = async (data: Wood) => {
    data.id = Number(wood_id); // wood_idを設定
    data.is_temporarily_stored = true;
    try {
      if (selectedFile) {
        const uploadData = {
          file: selectedFile,
          path: "excel_csv/wood",
        } as const satisfies SignedURLRequestToPut;
        const res = await mutateUploadFileToS3(uploadData);
        data.file_path = res;
        await mutateAsync(data);
      } else {
        await mutateAsync(data);
      }
      setSelectedFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: Wood) => {
    data.id = Number(wood_id); // wood_idを設定
    try {
      if (selectedFile) {
        const uploadData = {
          file: selectedFile,
          path: "excel_csv/wood",
        } as const satisfies SignedURLRequestToPut;
        const res = await mutateUploadFileToS3(uploadData);
        data.file_path = res;
        await mutateAsync(data);
      } else {
        await mutateAsync(data);
      }
      setSelectedFile(null);
      navigate(`/order/mailing/wood/${wood_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const watchedTitle = useWatch({ control, name: "title" });
  const watchedWoodDetails = useWatch({ control, name: "wood_details" });

  // すべてのフィールドが入力されているかを確認
  const areAllWoodDetailsComplete =
    watchedWoodDetails &&
    watchedWoodDetails.length > 0 &&
    watchedWoodDetails.every((woodDetail) =>
      Object.values(woodDetail).every((value) => value !== "" && value !== 0),
    );

  // WhiteButtonの活性化条件: タイトルまたはwood_detailsのいずれかに1つでも入力がある場合
  const isWhiteButtonDisabled =
    !watchedTitle &&
    !(
      watchedWoodDetails &&
      watchedWoodDetails[0] &&
      Object.values(watchedWoodDetails[0]).some((value) => value !== "")
    );

  // BlackButtonの活性化条件: タイトルが入力され、wood_detailsの最初の行がすべて入力されている場合
  const isBlackButtonDisabled = !watchedTitle || !areAllWoodDetailsComplete;

  if (isPending || getLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (error || getError || searchError) {
    return (
      <Error
        message={error?.message || getError?.message || searchError?.message}
      />
    );
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* 一時保存した投稿一覧 検索ボックス */}
      <SearchBox
        placeholder="一時保存したリスト一覧"
        isSmallScreen
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked} // ここで渡す
      />
      {searchClicked && woods && woods.length > 0 && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: 1,
            padding: 2,
          }}
        >
          {woods.map((wood) => (
            <Typography
              key={wood.id}
              sx={{
                padding: "8px 16px",
                borderBottom: "1px solid #e0e0e0",
                "&:hover": { backgroundColor: "#f5f5f5" },
              }}
              onClick={() => navigate(`/order/create_wood_list/${wood.id}`)}
            >
              {wood.title && wood.title !== "" ? wood.title : "タイトルなし"}
            </Typography>
          ))}
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        {/* 画像選択部分 */}
        <Box
          sx={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
            marginBottom: 3,
            position: "relative",
            backgroundColor: "#cacaca",
          }}
        >
          {/* 画像がある場合でもクリックでファイル選択ができるように、inputをラップ */}
          <IconButton
            color="primary"
            component="label"
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
          >
            <input
              hidden
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // CSVおよびExcelファイルのみに制限
              type="file"
              onChange={handleFileChange}
            />
          </IconButton>
          {!selectedFile && filePath ? (
            // S3から取得した画像を表示
            <Box
              component="img"
              src={filePath}
              alt="Selected"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          ) : selectedFile ? (
            // ローカルで選択された画像を表示
            <Box
              component="img"
              src={URL.createObjectURL(selectedFile)}
              alt="Selected"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          ) : (
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <IconButton color="primary" component="label">
                <PhotoCamera sx={{ fontSize: 50 }} />
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                />
              </IconButton>
              <Typography>CSV/Excelファイルを取りこむ</Typography>
            </Box>
          )}
        </Box>
        {/* タイトル入力欄 */}
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title ? "タイトルを入力してください" : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
        />
        <WoodTable
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
        />
        {/* 作成内容を一時保存 ボタン */}
        <WhiteButton
          isSmallScreen
          text={"作成内容を一時保存"}
          onClick={handleSubmit(handleSaveTemporarily)} // handleSaveTemporarily関数を使用
          disabled={isWhiteButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        />
        {/* お知らせを投稿 ボタン */}
        <BlackButton
          isSmallScreen
          text={"送付先選択へ"}
          type="submit"
          disabled={isBlackButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        />
      </form>
    </Box>
  );
};
