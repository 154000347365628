import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginLayout } from "./layout/LoginLayout";
import { SignIn } from "../pages/Auth/SignIn";
import { SignUp } from "../pages/Auth/SignUp";
import { HomeLayout } from "./layout/HomeLayout";
import { NotificationComponent } from "../pages/Home/Notification";
import { MessageRooms } from "../pages/Home/MessageRooms/MessageRooms";
import { Order } from "../pages/Home/Order";
import { CreateWoodList } from "../pages/Home/Wood/CreateWoodList";
import { AdminLayout } from "./layout/AdminLayout";
import { UserComponent } from "../pages/Home/User";
import { Profile } from "../pages/Home/Profile/index";
import { ProfileLayout } from "./layout/ProfileLayout";
import { CreateLogList } from "../pages/Home/Log/CreateLogList";
import { RequestList } from "../pages/Home/RequestList";
import { LogMailing } from "../pages/Home/Log/LogMailing";
import { ManagementLayout } from "./layout/ManagementLayout";
import { Stock } from "../pages/Home/Stock";
import { Document } from "../pages/Home/Management/Document";
import { Money } from "../pages/Home/Money";
import { InputWoodStock } from "../pages/Home/InputWoodStock";
import { OrderMessageRooms } from "../pages/Home/MessageRooms/OrderMessageRooms";
import { MessageRoom } from "../pages/Home/MessageRoom";
import { OrderMessageRoom } from "../pages/Home/OrderMessageRoom";
import { WoodTemporallyStored } from "../pages/Home/Wood/WoodTemporallyStored";
import { WoodOrderHistory } from "../pages/Home/Wood/WoodOrderHistory";
import { PrecutLayout } from "./layout/PrecutLayout";
import { SawmillLayout } from "./layout/SawmillLayout";
import { LogTemporallyStored } from "../pages/Home/Log/LogTemporallyStored";
import { LogOrderHistory } from "../pages/Home/Log/LogOrderHistory";
import { WoodMailing } from "../pages/Home/Wood/WoodMailing";
import { MessageRoomsLayout } from "./layout/MessageRoomsLayout";
import { LogMarketPrice } from "../pages/Home/LogMarketPrice/LogMarketPrice";
import { DataLayout } from "./layout/DataLayout";
import { LatestLogMarketPrice } from "../pages/Home/LogMarketPrice/LatestLogMarketPrice";
import { LogOffer } from "../pages/Home/Log/LogOffer";
import { WoodOffer } from "../pages/Home/Wood/WoodOffer";
import { CreateBlueprintList } from "../pages/Home/Blueprint/CreateBlueprintList";
import { BlueprintLayout } from "./layout/BlueprintLayout";
import { BlueprintMailing } from "../pages/Home/Blueprint/BlueprintMailing";
import { CreateBlueprintDetailList } from "../pages/Home/Blueprint/CreateBlueprintDetailList";
import { BlueprintTemporallyStored } from "../pages/Home/Blueprint/BlueprintTemporallyStored";
import { BlueprintOrderHistory } from "../pages/Home/Blueprint/BlueprintOrderHistory";
import { BlueprintOffer } from "../pages/Home/Blueprint/BlueprintOffer";
import { Quotation } from "../pages/Home/Quotation";
import { MessageRoomLayout } from "./layout/MessageRoomLayout";
import { ThreeSplitScreenLayout } from "./layout/ThreeSplitScreenLayout";
import { NotificationList } from "../pages/Home/Home/notification_list";
import { QuotationList } from "../pages/Home/Management/Document/QuotationList";
import { OrderFormList } from "../pages/Home/Management/Document/OrderFormList";
import { DeliveryNoteList } from "../pages/Home/Management/Document/DeliveryNoteList";
import { InvoiceList } from "../pages/Home/Management/Document/InvoiceList";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route
            path="/input_stock/wood/:wood_id"
            element={<InputWoodStock />}
          />
          <Route path="/order" element={<Order />} />
          <Route
            path="/log_market_price/latest"
            element={<LatestLogMarketPrice />}
          />
        </Route>
        <Route element={<ThreeSplitScreenLayout />}>
          <Route path="/home" element={<NotificationList />} />
          <Route
            path="/home/notification/:notification_id"
            element={<NotificationComponent />}
          />
          <Route
            path="/management/document/quotation_list"
            element={<QuotationList />}
          />
          <Route
            path="/management/document/order_form_list"
            element={<OrderFormList />}
          />
          <Route
            path="/management/document/delivery_note_list"
            element={<DeliveryNoteList />}
          />
          <Route
            path="/management/document/invoice_list"
            element={<InvoiceList />}
          />
          <Route path="/order/request_list" element={<RequestList />} />
        </Route>
        <Route element={<ProfileLayout />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<SawmillLayout />}>
          <Route path="/order/quotation/:wood_id" element={<Quotation />} />
          <Route path="/order/mailing/log/:log_id" element={<LogMailing />} />
          <Route
            path="/order/create_log_list/:log_id"
            element={<CreateLogList />}
          />
          <Route
            path="/order/log_temporally_stored"
            element={<LogTemporallyStored />}
          />
          <Route
            path="/order/log_order_history"
            element={<LogOrderHistory />}
          />
          <Route path="/order/log_offer" element={<LogOffer />} />
        </Route>
        <Route element={<PrecutLayout />}>
          <Route
            path="/order/create_blueprint_detail_list/:blueprint_id"
            element={<CreateBlueprintDetailList />}
          />
          <Route
            path="/order/create_wood_list/:wood_id"
            element={<CreateWoodList />}
          />
          <Route
            path="/order/wood_temporally_stored"
            element={<WoodTemporallyStored />}
          />
          <Route
            path="/order/wood_order_history"
            element={<WoodOrderHistory />}
          />
          <Route
            path="/order/mailing/wood/:wood_id"
            element={<WoodMailing />}
          />
          <Route path="/order/wood_offer" element={<WoodOffer />} />
        </Route>
        <Route element={<BlueprintLayout />}>
          <Route
            path="/order/create_blueprint_list/:blueprint_id"
            element={<CreateBlueprintList />}
          />
          <Route
            path="/order/mailing/blueprint/:blueprint_id"
            element={<BlueprintMailing />}
          />
          <Route
            path="/order/blueprint_temporally_stored"
            element={<BlueprintTemporallyStored />}
          />
          <Route
            path="/order/blueprint_order_history"
            element={<BlueprintOrderHistory />}
          />
          <Route path="/order/blueprint_offer" element={<BlueprintOffer />} />
        </Route>
        <Route element={<MessageRoomsLayout />}>
          <Route path="/talk/message_rooms" element={<MessageRooms />} />
          <Route
            path="/talk/order_message_rooms"
            element={<OrderMessageRooms />}
          />
        </Route>
        <Route element={<MessageRoomLayout />}>
          <Route
            path="/talk/message_room/:message_room_id"
            element={<MessageRoom />}
          />
          <Route
            path="/talk/wood_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/log_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/blueprint_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
        </Route>
        <Route element={<ManagementLayout />}>
          <Route path="/management/stock" element={<Stock />} />
          <Route path="/management/document" element={<Document />} />
          <Route path="/management/money" element={<Money />} />
        </Route>
        <Route element={<DataLayout />}>
          <Route path="/data/log_market_price" element={<LogMarketPrice />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="/user" element={<UserComponent />} />
        </Route>
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </BrowserRouter>
  );
};
