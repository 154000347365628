import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SearchBox } from "../../../uis/TextField/SearchBox";
import Avatar from "../../../uis/Avatar";
import { Error } from "../../../uis/Error";
import { useState } from "react";
import { useGetNotifications } from "../../../hooks/useNotificationHook";

export const NotificationList = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const [searchText, setSearchText] = useState("");
  const { data: notifications, error: notificationsError } =
    useGetNotifications(searchText);
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  // エラー時の処理
  if (notificationsError) {
    return <Error message={notificationsError?.message} />;
  }

  return (
    <Box sx={{ width: "90%" }}>
      {/* 4: お知らせを検索ボックス */}
      <Box sx={{ width: "90%" }}>
        <SearchBox
          isSmallScreen
          placeholder="お知らせを検索(会社名など)"
          onClick={handleSearchBoxClick}
          onChange={handleSearchBoxChange}
          value={searchText}
          searchClicked={searchClicked} // ここで渡す
        />
      </Box>
      {/* 5: 会社一覧 */}
      <Box
        sx={{
          width: "100%",
          marginTop: isSmallScreen ? 1 : 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {notifications &&
          notifications.length > 0 &&
          notifications.map((notification) => (
            <Box
              key={notification.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: isSmallScreen ? 1 : 2,
                padding: isSmallScreen ? 1 : 2,
                border: "1px solid lightgray",
                borderRadius: "8px",
              }}
            >
              {/* 会社アイコン */}
              <Avatar user={notification.user} isSmallScreen={isSmallScreen} />
              {/* 会社名とお知らせ内容 */}
              <Box>
                <Typography variant={isSmallScreen ? "body1" : "h6"}>
                  {notification.user.company_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {notification.title}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
