import React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  SxProps,
  Theme,
  Select,
  MenuItem,
} from "@mui/material";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";

interface FormInputProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: Path<T>;
  isError: boolean;
  errorMessage?: string;
  type?: React.HTMLInputTypeAttribute;
  options?: { label: string; value: string | number }[];
  pattern?: { value: RegExp; message: string };
  placeholder: string;
  required?: boolean;
  defaultValue?: string | number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  labelName?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  isSelected?: boolean;
}

const FormInput = <T extends FieldValues>({
  register,
  name,
  isError,
  errorMessage,
  type = "text",
  options = [],
  pattern,
  placeholder,
  required = true,
  defaultValue,
  onChange,
  labelName,
  disabled = false,
  sx,
  isSelected = false,
}: FormInputProps<T>) => (
  <FormControl
    error={isError}
    disabled={disabled}
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "1rem",
      ...sx,
    }}
  >
    {labelName && (
      <FormLabel sx={{ width: "30%" }} htmlFor={labelName}>
        {labelName}
      </FormLabel>
    )}
    {isSelected ? (
      <Select
        id={labelName}
        {...register(name, {
          required: required ? `${placeholder}を選択してください` : undefined,
        })}
        defaultValue={defaultValue}
        sx={{
          width: "70%",
          borderRadius: "0px",
          border: "none",
          paddingLeft: "1rem",
        }}
        disabled={disabled}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    ) : (
      <Input
        id={labelName}
        placeholder={placeholder}
        type={type}
        {...register(name, {
          required: required ? `${placeholder}を入力してください` : undefined,
          setValueAs: (value) => (value === "" ? undefined : value),
          pattern,
        })}
        defaultValue={defaultValue}
        onChange={onChange}
        sx={{
          width: "70%",
          borderRadius: "0px",
          border: "none",
          paddingLeft: "1rem",
        }}
        disabled={disabled}
      />
    )}
    {errorMessage && (
      <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
    )}
  </FormControl>
);

export default FormInput;
