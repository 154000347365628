import { TabbedLayout } from "./TabbedLayout";

export const ManagementLayout = () => {
  const tabs = [
    { label: "在庫", path: "/management/stock" },
    { label: "書類", path: "/management/document" },
    { label: "お金", path: "/management/money" },
  ] as const;

  return <TabbedLayout tabs={tabs} />;
};
