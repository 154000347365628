import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { documentKeys, logKeys, logOrderKeys } from "../../utils/query-key";
import {
  ReadonlyResponse,
  ReadonlyResponseWithId,
  SearchRequest,
  TimeStamp,
} from "../../utils/types/general_type";
import { FIVE_MINUTES, SEARCH_BOX_ITEMS } from "../../utils/constant";
import { User } from "../useUserHook";
import { Log } from "./Log/type";
import { ItemContractRequest } from "../../utils/types/item_type";

export interface LogOrder extends TimeStamp {
  id: number;
  log_id: number;
  receiver_id?: string;
  is_active: boolean;
  is_contract_signed: boolean;
  is_received: boolean;
  log: Log;
  user: User;
  log_quotation?: LogQuotation;
  log_order_form?: LogOrderForm;
  log_delivery_note?: LogDeliveryNote;
  log_invoice?: LogInvoice;
}

interface LogQuotation {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface LogOrderForm {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface LogDeliveryNote {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface LogInvoice {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

export interface CreateLogOrder {
  logId: number;
  isAll: boolean;
  userIds: string[];
}

export type LogOrders = ReadonlyArray<LogOrder>;

export function useCreateLogOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateLogOrder) => {
      const { token } = await getAccessToken();
      // userIdsが不要な場合はnullにする
      const userIds = data.isAll ? undefined : data.userIds;

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/log_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          log_id: data.logId,
          is_all: data.isAll,
          user_ids: userIds, // userIdsがundefinedなら送信しない
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: logKeys.logs_by_filter(false, true),
      });
    },
  });
}

export function useContractLogOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: ItemContractRequest) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/log_order/contract`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_id: req.orderId,
            log_id: req.itemId,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: logOrderKeys.search(""),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.orderForms,
      });
    },
  });
}

export function useSearchLogOffers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logOrderKeys.search(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/log_orders/user/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<LogOrders>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useCancelLogOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (logId: number) => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/log_order`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ log_id: logId }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: logKeys.logs_by_filter(false, true),
      });
    },
  });
}
