import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import {
  BlueprintOrderMessageRoom,
  LogOrderMessageRoom,
  WoodOrderMessageRoom,
} from "../../hooks/useOrderMessageRoomHook";
import { FileDownload } from "../../uis/File/FileDownload";
import { WoodDetail } from "../../hooks/Wood/Wood/type";
import { LogDetail } from "../../hooks/Log/Log/type";
import { BlueprintDetail } from "../../hooks/Blueprint/Blueprint/type"; // BlueprintDetail をインポート
import { isBlueprint, isLog, isWood } from "../Home/OrderMessageRoom";
import { FileReference } from "../../utils/types/item_type";

interface DetailsTabProps {
  data:
    | Readonly<LogOrderMessageRoom>
    | Readonly<WoodOrderMessageRoom>
    | Readonly<BlueprintOrderMessageRoom>;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ data }) => {
  // タイトルと総額の取得
  let title: string | undefined = undefined;
  let totalAmount: number | undefined = undefined;
  let quotation: FileReference | null = null;
  let orderForm: FileReference | null = null;
  let deliveryNote: FileReference | null = null;
  let invoice: FileReference | null = null;

  if (isLog(data)) {
    // LogOrderMessageRoom の処理
    title = data.log_order.log.title;
    totalAmount = data.log_order.log.total_amount;
    quotation = data.log_order.log_quotation
      ? { id: data.log_order.log_quotation.id, itemType: "log" }
      : null;
    orderForm = data.log_order.log_order_form
      ? { id: data.log_order.log_order_form.id, itemType: "log" }
      : null;
    deliveryNote = data.log_order.log_delivery_note
      ? { id: data.log_order.log_delivery_note.id, itemType: "log" }
      : null;
    invoice = data.log_order.log_invoice
      ? { id: data.log_order.log_invoice.id, itemType: "log" }
      : null;
  } else if (isWood(data)) {
    // WoodOrderMessageRoom の処理
    title = data.wood_order.wood.title;
    totalAmount = data.wood_order.wood.total_amount;
    quotation = data.wood_order.wood_quotation
      ? { id: data.wood_order.wood_quotation.id, itemType: "wood" }
      : null;
    orderForm = data.wood_order.wood_order_form
      ? { id: data.wood_order.wood_order_form.id, itemType: "wood" }
      : null;
    deliveryNote = data.wood_order.wood_delivery_note
      ? { id: data.wood_order.wood_delivery_note.id, itemType: "wood" }
      : null;
    invoice = data.wood_order.wood_invoice
      ? { id: data.wood_order.wood_invoice.id, itemType: "wood" }
      : null;
  } else if (isBlueprint(data)) {
    // BlueprintOrderMessageRoom の処理
    title = data.blueprint_order.blueprint.title;
    totalAmount = data.blueprint_order.blueprint.total_amount;
    quotation = data.blueprint_order.blueprint_quotation
      ? {
          id: data.blueprint_order.blueprint_quotation.id,
          itemType: "blueprint",
        }
      : null;
    orderForm = data.blueprint_order.blueprint_order_form
      ? {
          id: data.blueprint_order.blueprint_order_form.id,
          itemType: "blueprint",
        }
      : null;
    deliveryNote = data.blueprint_order.blueprint_delivery_note
      ? {
          id: data.blueprint_order.blueprint_delivery_note.id,
          itemType: "blueprint",
        }
      : null;
    invoice = data.blueprint_order.blueprint_invoice
      ? { id: data.blueprint_order.blueprint_invoice.id, itemType: "blueprint" }
      : null;
  } else {
    // 安全策としてデフォルト値を設定
    title = undefined;
    totalAmount = undefined;
    quotation = null;
    orderForm = null;
    deliveryNote = null;
    invoice = null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "background.paper",
        p: 2,
      }}
    >
      <Box sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        {totalAmount !== undefined && (
          <Typography variant="subtitle1" gutterBottom>
            見積もり金額（合計）: ¥{totalAmount}
          </Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <List>
          {isLog(data)
            ? data.log_order.log.log_details.map(
                (detail: LogDetail, index: number) => (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemText
                      primary={detail.name}
                      secondary={
                        <>
                          <Typography variant="body2">
                            原産地: {detail.origin}
                          </Typography>
                          <Typography variant="body2">
                            樹種: {detail.species}
                          </Typography>
                          <Typography variant="body2">
                            等級: {detail.grade}
                          </Typography>
                          <Typography variant="body2">
                            末口径: {detail.top_diameter}
                          </Typography>
                          <Typography variant="body2">
                            長さ: {detail.length}
                          </Typography>
                          <Typography variant="body2">
                            単材積: {detail.unit_volume}
                          </Typography>
                          <Typography variant="body2">
                            本数: {detail.quantity}
                          </Typography>
                          <Typography variant="body2">
                            材積: {detail.total_volume}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ),
              )
            : isWood(data)
              ? data.wood_order.wood.wood_details.map(
                  (detail: WoodDetail, index: number) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={detail.name}
                        secondary={
                          <>
                            <Typography variant="body2">
                              原産地: {detail.origin}
                            </Typography>
                            <Typography variant="body2">
                              樹種: {detail.species}
                            </Typography>
                            <Typography variant="body2">
                              等級: {detail.grade}
                            </Typography>
                            <Typography variant="body2">
                              断面: {detail.section}
                            </Typography>
                            <Typography variant="body2">
                              長さ: {detail.length}
                            </Typography>
                            <Typography variant="body2">
                              単材積: {detail.unit_volume}
                            </Typography>
                            <Typography variant="body2">
                              本数: {detail.quantity}
                            </Typography>
                            <Typography variant="body2">
                              材積: {detail.total_volume}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ),
                )
              : isBlueprint(data)
                ? data.blueprint_order.blueprint.blueprint_details.map(
                    (detail: BlueprintDetail, index: number) => (
                      <ListItem key={index} alignItems="flex-start">
                        <ListItemText
                          primary={detail.name}
                          secondary={
                            <>
                              <Typography variant="body2">
                                原産地: {detail.origin}
                              </Typography>
                              <Typography variant="body2">
                                樹種: {detail.species}
                              </Typography>
                              <Typography variant="body2">
                                等級: {detail.grade}
                              </Typography>
                              <Typography variant="body2">
                                断面: {detail.section}
                              </Typography>
                              <Typography variant="body2">
                                長さ: {detail.length}
                              </Typography>
                              <Typography variant="body2">
                                単材積: {detail.unit_volume}
                              </Typography>
                              <Typography variant="body2">
                                本数: {detail.quantity}
                              </Typography>
                              <Typography variant="body2">
                                材積: {detail.total_volume}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    ),
                  )
                : null}
        </List>
        <FileDownload
          file={{ id: quotation?.id, itemType: quotation?.itemType }}
          category="quotation"
        />
        <FileDownload
          file={{ id: orderForm?.id, itemType: orderForm?.itemType }}
          category="order_form"
        />
        {deliveryNote && (
          <FileDownload
            file={{ id: deliveryNote.id, itemType: deliveryNote.itemType }}
            category="delivery_note"
          />
        )}
        {invoice && (
          <FileDownload
            file={{ id: invoice.id, itemType: invoice.itemType }}
            category="invoice"
          />
        )}
      </Box>
    </Box>
  );
};
