import {
  BlueprintOrders,
  useContractBlueprintOrder,
  useSearchBlueprintOffers,
} from "../../../hooks/Blueprint/useBlueprintOrderHook";
import { OfferComponent } from "../../common/OfferComponent";

export const BlueprintOffer = () => {
  return (
    <OfferComponent<BlueprintOrders>
      useSearchOffers={useSearchBlueprintOffers}
      useContractOrder={useContractBlueprintOrder}
    />
  );
};
