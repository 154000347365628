import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "../../uis/Bars/Header";
import { SawmillRoute } from "../SawmillRoute";
import Sidebar from "../../uis/Bars/Sidebar";
import { Order } from "../../pages/Home/Order";

export const SawmillLayout = () => {
  return (
    <SawmillRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={true} />
        <Box
          sx={{
            width: "80%",
          }}
        >
          <Header title="丸太" />
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Order />
            </Box>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </SawmillRoute>
  );
};
