import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PrivateRoute } from "../PrivateRoute";
import { TabBar } from "../../uis/Bars/Tab";
import Sidebar from "../../uis/Bars/Sidebar";
import { Header } from "../../uis/Bars/Header";
import { MessageRooms } from "../../pages/Home/MessageRooms/MessageRooms";
import { messageTabs } from "./MessageRoomsLayout";
import { OrderMessageRooms } from "../../pages/Home/MessageRooms/OrderMessageRooms";

export const MessageRoomLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // パスからタブのインデックスを取得
  const getTabIndexFromPath = (path: string) => {
    const index = messageTabs.findIndex((tab) => tab.path === path);
    return index >= 0 ? index : 0; // デフォルトは最初のタブ
  };

  // インデックスからパスを取得
  const getPathFromTabIndex = (index: number) => {
    return messageTabs[index]?.path || messageTabs[0].path; // デフォルトは最初のパス
  };

  const [selectedTab, setSelectedTab] = useState<number>(
    getTabIndexFromPath(location.pathname),
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const newPath = getPathFromTabIndex(newValue);
    navigate(newPath);
  };

  return (
    <PrivateRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={true} />
        <Box
          sx={{
            width: "80%",
          }}
        >
          <Header title={"トーク"} />
          <TabBar
            tabs={messageTabs}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                width: "40%",
              }}
            >
              {location.pathname.includes("order") ? (
                <OrderMessageRooms /> // orderが含まれている場合はOrderMessageRoomsを表示
              ) : (
                <MessageRooms /> // それ以外はMessageRoomsを表示
              )}
            </Box>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </PrivateRoute>
  );
};
