import { TemporallyStored } from "../../common/TemporallyStored";
import { useGetBlueprints } from "../../../hooks/Blueprint/Blueprint/useBlueprintHook";
import { blueprintColumnsForItem } from "../../../utils/columns";

export const BlueprintTemporallyStored = () => {
  return (
    <TemporallyStored<"blueprint">
      itemType="blueprint"
      useGetItems={useGetBlueprints}
      columns={blueprintColumnsForItem}
    />
  );
};
