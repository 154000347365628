import { Wood } from "../../../hooks/Wood/Wood/type";
import { TableComponent, TableComponentProps } from "../../../uis/Table";
import { woodColumnsForTable } from "../../../utils/columns";
import { defaultWoodRow } from "../../../utils/rows";

export const WoodTable = ({
  register,
  append,
  fields,
  errors,
  remove,
}: TableComponentProps<Wood, "wood_details">) => {
  return (
    <TableComponent<Wood, "wood_details">
      register={register}
      append={append}
      fields={fields}
      errors={errors}
      remove={remove}
      fieldArrayName="wood_details"
      columns={woodColumnsForTable}
      defaultRow={defaultWoodRow}
    />
  );
};
