import { BlueprintBase } from "../../../../hooks/Blueprint/Blueprint/type";
import { TableComponent, TableComponentProps } from "../../../../uis/Table";
import { blueprintColumnsForTable } from "../../../../utils/columns";
import { defaultBlueprintRow } from "../../../../utils/rows";

export const BlueprintTable = ({
  register,
  append,
  fields,
  errors,
  remove,
}: TableComponentProps<BlueprintBase, "blueprint_details">) => {
  return (
    <TableComponent<BlueprintBase, "blueprint_details">
      register={register}
      append={append}
      fields={fields}
      errors={errors}
      remove={remove}
      fieldArrayName="blueprint_details"
      columns={blueprintColumnsForTable}
      defaultRow={defaultBlueprintRow}
      additionalColumns={[
        {
          label: "金額",
          field: "amount",
          type: "number",
        },
      ]}
    />
  );
};
