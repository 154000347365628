import { Outlet } from "react-router-dom";
import { PublicRoute } from "../PublicRoute";
import { Box } from "@mui/material";
import Sidebar from "../../uis/Bars/Sidebar";

export const LoginLayout = () => {
  return (
    <PublicRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={false} />
        <Outlet />
      </Box>
    </PublicRoute>
  );
};
