import { useGetBlueprint } from "../../../hooks/Blueprint/Blueprint/useBlueprintHook";
import { useSearchBlueprintUsers } from "../../../hooks/useUserHook";
import {
  CreateBlueprintOrder,
  useCreateBlueprintOrder,
} from "../../../hooks/Blueprint/useBlueprintOrderHook";
import { MailingComponent } from "../../common/MailingComponent";
import { BlueprintBase } from "../../../hooks/Blueprint/Blueprint/type";

export const BlueprintMailing = () => {
  const { mutateAsync: mutateCreateAsync } = useCreateBlueprintOrder();
  const createOrder = (orderData: CreateBlueprintOrder) =>
    mutateCreateAsync(orderData);

  return (
    <MailingComponent<BlueprintBase, CreateBlueprintOrder>
      idParamName="blueprint_id"
      useGetData={useGetBlueprint}
      useSearchUsers={useSearchBlueprintUsers}
      createOrder={createOrder}
      orderDataKey="blueprintId"
      navigateTo="/order"
    />
  );
};
