import { useNavigate } from "react-router-dom";
import { MenuItemComponent } from "../../../../uis/Item/MenuItem";

export const Document = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: "書類",
      items: [
        { text: "見積書一覧", link: "/management/document/quotation_list" },
        { text: "注文書一覧", link: "/management/document/order_form_list" },
        { text: "納品書一覧", link: "/management/document/delivery_note_list" },
        { text: "請求書一覧", link: "/management/document/invoice_list" },
        {
          text: "産地証明書の申請",
          link: "/management/document/input_shipping",
        },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    navigate(link);
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={false}
    />
  );
};
