import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Users } from "../../hooks/useUserHook";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import { useCreateMessageRoom } from "../../hooks/Message/useMessageRoomHook";
import { toast } from "react-toastify";
import {
  OrderStatusWithId,
  ReadonlyResponse,
} from "../../utils/types/general_type";
import { Wood } from "../../hooks/Wood/Wood/type";
import { Log } from "../../hooks/Log/Log/type";
import { BlueprintBase } from "../../hooks/Blueprint/Blueprint/type";
import { BlueprintImageComponent } from "../../uis/BlueprintImage";

interface MailingComponentProps<TData, TOrderData> {
  idParamName: string;
  useGetData: (params: OrderStatusWithId) => {
    data: TData | undefined;
    error: Error | null;
    isPending: boolean;
  };
  useSearchUsers: (params: { searchText: string; limit: number }) => {
    data: Users | undefined;
    error: Error | null;
  };
  createOrder: (orderData: TOrderData) => Promise<ReadonlyResponse>;
  orderDataKey: keyof TOrderData;
  navigateTo: string;
}

export const MailingComponent = <
  TData extends Log | Wood | BlueprintBase,
  TOrderData extends object,
>({
  idParamName,
  useGetData,
  useSearchUsers,
  createOrder,
  orderDataKey,
  navigateTo,
}: MailingComponentProps<TData, TOrderData>) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [userIds, setUserIds] = useState<string[]>([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams<{ [key: string]: string }>();
  const idString = params[idParamName];
  const id = Number(idString);
  const { data, error, isPending } = useGetData({
    id,
    isTemporarilyStored: true,
    isOrdered: false,
  });
  const { data: searchUsers, error: searchError } = useSearchUsers({
    searchText,
    limit: 10,
  });

  const { mutateAsync: createMessageRoom, isPending: isMutatePending } =
    useCreateMessageRoom();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleCreate = async (userId?: string) => {
    if (!userId) {
      navigate("/signin");
      return;
    }
    try {
      const res = await createMessageRoom({ partner_id: userId });
      navigate(`/talk/message_room/${res.message_room_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrder = async () => {
    if (data) {
      try {
        const orderData = {
          [orderDataKey]: data.id,
          isAll: false,
          userIds: userIds,
        } as TOrderData;
        await createOrder(orderData);
        navigate(navigateTo);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAllOrder = async () => {
    if (data) {
      try {
        const orderData = {
          [orderDataKey]: data.id,
          isAll: true,
          userIds: [],
        } as TOrderData;
        await createOrder(orderData);
        navigate(navigateTo);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const toggleUserSelection = (userId?: string) => {
    if (!userId) {
      toast.error("ユーザーが存在しません");
      return;
    }
    setUserIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId],
    );
  };

  // 型ガード関数を修正
  function isLog(data: Log | Wood | BlueprintBase): data is Log {
    return (data as Log).log_details !== undefined;
  }

  function isWood(data: Log | Wood | BlueprintBase): data is Wood {
    return (data as Wood).wood_details !== undefined;
  }

  function isBlueprintBase(
    data: Log | Wood | BlueprintBase,
  ): data is BlueprintBase {
    return (data as BlueprintBase).blueprint_details !== undefined;
  }

  if (isPending) {
    return <Loading />;
  }

  if (error || searchError) {
    return <Error message={error?.message || searchError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowDetails(!showDetails)}
        >
          リストの詳細を確認する
        </Button>
      </Box>

      {showDetails && data && (
        <Box sx={{ width: "100%", mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            {data.title}
          </Typography>
          {"total_amount" in data && data.total_amount !== undefined && (
            <Typography variant="subtitle1" gutterBottom>
              見積もり金額（合計）: ¥{data.total_amount}
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />
          <List>
            {isLog(data)
              ? data.log_details.map((detail, index) => (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemText
                      primary={detail.name}
                      secondary={
                        <>
                          <Typography variant="body2">
                            原産地: {detail.origin}
                          </Typography>
                          <Typography variant="body2">
                            樹種: {detail.species}
                          </Typography>
                          <Typography variant="body2">
                            等級: {detail.grade}
                          </Typography>
                          <Typography variant="body2">
                            末口径: {detail.top_diameter}
                          </Typography>
                          <Typography variant="body2">
                            長さ: {detail.length}
                          </Typography>
                          <Typography variant="body2">
                            単材積: {detail.unit_volume}
                          </Typography>
                          <Typography variant="body2">
                            本数: {detail.quantity}
                          </Typography>
                          <Typography variant="body2">
                            材積: {detail.total_volume}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))
              : isWood(data)
                ? data.wood_details.map((detail, index) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={detail.name}
                        secondary={
                          <>
                            <Typography variant="body2">
                              原産地: {detail.origin}
                            </Typography>
                            <Typography variant="body2">
                              樹種: {detail.species}
                            </Typography>
                            <Typography variant="body2">
                              等級: {detail.grade}
                            </Typography>
                            <Typography variant="body2">
                              断面: {detail.section}
                            </Typography>
                            <Typography variant="body2">
                              長さ: {detail.length}
                            </Typography>
                            <Typography variant="body2">
                              単材積: {detail.unit_volume}
                            </Typography>
                            <Typography variant="body2">
                              本数: {detail.quantity}
                            </Typography>
                            <Typography variant="body2">
                              材積: {detail.total_volume}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                : isBlueprintBase(data)
                  ? data.blueprint_images.map((image, index) => (
                      <ListItem key={index} alignItems="flex-start">
                        <BlueprintImageComponent blueprintImage={image} />
                      </ListItem>
                    ))
                  : null}
          </List>
        </Box>
      )}

      <SearchBox
        placeholder="会社を検索"
        isSmallScreen={isSmallScreen}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={"選択なし"}
            secondary={
              <Box sx={{ mt: 1 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleAllOrder}
                  disabled={isMutatePending}
                >
                  全員に注文する
                </Button>
              </Box>
            }
          />
        </ListItem>
        {searchUsers &&
          searchUsers.length > 0 &&
          searchUsers.map((user) => (
            <ListItem key={user.id} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar user={user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <ListItemText
                primary={user.company_name}
                secondary={
                  <Box sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => handleCreate(user.user_id)}
                    >
                      チャットを送る
                    </Button>
                    {userIds.includes(user.user_id ?? "") ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => toggleUserSelection(user.user_id)}
                      >
                        キャンセル
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => toggleUserSelection(user.user_id)}
                      >
                        選択をする
                      </Button>
                    )}
                  </Box>
                }
              />
            </ListItem>
          ))}
      </List>
      {userIds.length !== 0 && (
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOrder}
            disabled={isMutatePending}
          >
            注文する
          </Button>
        </Box>
      )}
    </Box>
  );
};
