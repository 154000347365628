import React from "react";
import { TextField } from "@mui/material";
import {
  RegisterOptions,
  Controller,
  FieldValues,
  Path,
  Control,
  FieldErrors,
} from "react-hook-form";

interface TextFieldFormProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> {
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  name: TName;
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  labelName?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
}

export const TextFieldForm = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  rules,
  name,
  control,
  errors,
  labelName,
  type = "text", // デフォルトタイプを"text"に設定
}: TextFieldFormProps<TFieldValues, TName>) => {
  // エラーメッセージを安全に取得
  const fieldError = errors[name];
  const errorMessage =
    fieldError && typeof fieldError.message === "string"
      ? fieldError.message
      : undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          label={labelName}
          type={type}
          variant="outlined"
          fullWidth
          error={!!fieldError}
          helperText={errorMessage}
          InputProps={{
            sx: {
              backgroundColor: "#FFFFFF",
              borderColor: "gray",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "gray",
              },
              "&:hover fieldset": {
                borderColor: "gray",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#FFFACD", // 薄黄色
              },
            },
          }}
        />
      )}
    />
  );
};
