import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ProfileRoute } from "../ProfileRoute";
import Sidebar from "../../uis/Bars/Sidebar";

export const ProfileLayout = () => {
  return (
    <ProfileRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={true} />
        <Outlet />
      </Box>
    </ProfileRoute>
  );
};
