import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Stack,
  FormControl,
  Avatar,
  FormLabel,
  Input,
  Divider,
  Typography,
} from "@mui/material";
import { useUpdateUser } from "../../../hooks/useUserHook";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import FormInput from "../../../uis/Forms/FormInput";
import { useForm } from "react-hook-form";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/S3/useSignedURLHook";
import { validation } from "../../../utils/validation";
import { UpdateUserSchema, UpdateUserType } from "../../../utils/schema";
import { toast } from "react-toastify";
import { industries } from "../../../utils/industry";
import { UserContext } from "../../../contexts/UserContext";
import { Color } from "../../../utils/color";

export const UserForm: React.FC = () => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useContext(UserContext);
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const {
    data: imagePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const { mutateAsync } = useUpdateUser();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<UpdateUserType>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false); // 編集モードの状態

  useEffect(() => {
    if (user) {
      if (user.industry !== undefined) {
        setValue("industry", user.industry);
      }
      if (user.company_name !== undefined) {
        setValue("companyName", user.company_name);
      }
      if (user.phone_number !== undefined) {
        setValue("phoneNumber", user.phone_number);
      }
      setValue("companyImage", user.company_image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (data: UpdateUserType) => {
    let res: string | undefined = undefined;
    try {
      if (file !== null) {
        const uploadData = {
          file,
          path: "img/user",
        } as const satisfies SignedURLRequestToPut;
        res = await mutateUploadFileToS3(uploadData);
      }
      const updateData = {
        ...data,
        companyImage: res,
      };
      const validationResponse = validation(updateData, UpdateUserSchema);
      if (validationResponse.success) {
        await mutateAsync(updateData);
        setIsEditMode(false); // 編集モードを解除
      } else {
        toast.error(validationResponse.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      setFile(file);
      const fileUrl = URL.createObjectURL(file);
      setAvatarUrl(fileUrl);
    }
  };

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  if (getLoading) {
    return <Loading />;
  }

  if (getError) {
    return <Error message={getError?.message} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* トップラベルと編集ボタン */}
      <Box
        sx={{
          backgroundColor: "#f0f0f0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
          width: "100%",
        }}
      >
        <Typography variant="h6" sx={{ color: Color.Main, fontWeight: "bold" }}>
          アカウント情報
        </Typography>
        <Button
          onClick={isEditMode ? handleSubmit(onSubmit) : toggleEditMode}
          sx={{
            backgroundColor: isEditMode ? "#B80003" : Color.Main,
            color: "white",
            padding: "6px 1.5rem",
            "&:hover": {
              backgroundColor: isEditMode ? "#B80003" : Color.Main,
            },
          }}
          disabled={isSubmitting}
        >
          {isEditMode ? "編集内容を更新する" : "編集する"}
        </Button>
      </Box>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
        }}
      >
        <Stack
          alignItems="stretch"
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          {/* プロフィール画像とアップロードフォーム */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormLabel>{"プロフィール画像"}</FormLabel>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                {...register("companyImage", {
                  onChange: handleImageChange,
                })}
                ref={(e) => {
                  register("companyImage").ref(e);
                  inputFileRef.current = e;
                }}
              />
              <Avatar
                alt="Company Icon"
                src={avatarUrl || imagePath}
                sx={{
                  width: isSmallScreen ? 90 : 100,
                  height: isSmallScreen ? 90 : 100,
                  marginBottom: isSmallScreen ? 1 : 2,
                  cursor: "pointer",
                }}
                onClick={handleAvatarClick}
              />
            </FormControl>

            {/* アップロードフォーム */}
            {isEditMode && (
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  width: 100,
                  height: 100,
                  marginLeft: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleAvatarClick}
              >
                <Typography variant="h6" color="#ccc">
                  + upload
                </Typography>
              </Box>
            )}
          </Box>
          <Divider sx={{ backgroundColor: "#ccc" }} />

          {/* フォーム項目 */}
          <FormInput<UpdateUserType>
            register={register}
            name="industry"
            isError={!!errors.industry}
            errorMessage={errors.industry?.message}
            isSelected={true}
            options={industries.map((industry, index) => ({
              label: industry,
              value: index,
            }))}
            placeholder="業種を選択してください"
            labelName="業種（必須）"
            defaultValue={user?.industry}
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormInput<UpdateUserType>
            placeholder="会社名（必須）"
            register={register}
            name="companyName"
            isError={!!errors.companyName}
            errorMessage={errors.companyName?.message}
            defaultValue={user?.company_name}
            labelName="会社名（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormControl
            disabled={true}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <FormLabel sx={{ width: "30%" }}>担当者メールアドレス</FormLabel>
            <Input
              placeholder="担当者メールアドレス(必須)"
              type="email"
              defaultValue={user?.email}
              sx={{
                width: "70%",
                borderRadius: "0px",
                border: "none",
                paddingLeft: "1rem",
              }}
            />
          </FormControl>
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormInput<UpdateUserType>
            placeholder="担当者電話番号（必須）"
            register={register}
            name="phoneNumber"
            isError={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber?.message}
            pattern={{
              value: /^[0-9\-+]+$/,
              message: "有効な電話番号を入力してください(ハイフンなし)",
            }}
            defaultValue={user?.phone_number}
            labelName="担当者電話番号（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormInput<UpdateUserType>
            placeholder="郵便番号 /ハイフンあり（必須）"
            register={register}
            name="postalCode"
            isError={!!errors.postalCode}
            errorMessage={errors.postalCode?.message}
            pattern={{
              value: /^\d{3}-\d{4}$/,
              message: "有効な郵便番号を入力してください（例: 123-4567）",
            }}
            defaultValue={user?.postal_code}
            labelName="郵便番号（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormInput<UpdateUserType>
            placeholder="住所（必須）"
            register={register}
            name="address"
            isError={!!errors.address}
            errorMessage={errors.address?.message}
            defaultValue={user?.address}
            labelName="住所（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormInput<UpdateUserType>
            placeholder="法人番号（任意）"
            register={register}
            name="corporateNumber"
            isError={!!errors.corporateNumber}
            errorMessage={errors.corporateNumber?.message}
            pattern={{
              value: /^\d{13}$/,
              message: "法人番号は13桁の数字で入力してください",
            }}
            defaultValue={user?.corporate_number}
            labelName="法人番号（任意）"
            required={false}
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />
        </Stack>
      </form>
    </Box>
  );
};
