import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  List,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
import { AttachFile, Delete } from "@mui/icons-material";
import { MessageItem } from "../../uis/Item/MessageItem";
import { ReadonlyResponse } from "../../utils/types/general_type";
import { MessageBase, SendMessage } from "../../hooks/Message/useMessageHook";
import { User } from "../../hooks/useUserHook";
import { IssueButtons } from "../../uis/Button/IssueButtons";
import { ItemMessageType } from "../../utils/types/item_type";

interface MessageTabProps<T extends MessageBase> {
  messages: T[];
  user: User | undefined;
  partner: User | undefined;
  mutateAsync: (data: SendMessage) => Promise<ReadonlyResponse>;
  isMutatePending: boolean;
  sessionUserId: string | undefined;
  type: ItemMessageType | undefined;
  isDeliveryNoteButtonShown: boolean;
  isInvoiceButtonShown: boolean;
}

export const MessageTab = <T extends MessageBase>({
  messages,
  user,
  partner,
  mutateAsync,
  isMutatePending,
  sessionUserId,
  type,
  isDeliveryNoteButtonShown,
  isInvoiceButtonShown,
}: MessageTabProps<T>) => {
  const [inputMessage, setInputMessage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // 自動スクロール
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleSendMessage = async () => {
    if (isMutatePending) return;
    const sendMessageData: SendMessage = {
      content: inputMessage,
      file: selectedFile,
    };

    try {
      await mutateAsync(sendMessageData);
      setInputMessage(null);
      setSelectedFile(null); // ファイル選択をリセット
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "85vh", // 画面全体の高さを使用
        width: "100%",
        bgcolor: "background.paper",
        borderLeft: "1px solid #e0e0e0",
      }}
    >
      <Box sx={{ flex: 1, overflowY: "auto", p: 2 }}>
        <List>
          {messages.map((message) => (
            <MessageItem
              key={message.id}
              message={message}
              sessionUserId={sessionUserId}
              user={user}
              partner={partner}
              type={type}
            />
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Box>

      {/* ファイルプレビュー */}
      {selectedFile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#f5f5f5",
            p: 1,
            mb: 1,
            borderRadius: 1,
          }}
        >
          {selectedFile.type.startsWith("image/") ? (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt={selectedFile.name}
              style={{ maxHeight: 100, marginRight: 16, borderRadius: 4 }}
            />
          ) : (
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {selectedFile.name}
            </Typography>
          )}
          <IconButton color="error" onClick={handleRemoveFile}>
            <Delete />
          </IconButton>
        </Box>
      )}
      {type !== "message" && (
        <IssueButtons
          type={type}
          isDeliveryNoteButtonShown={isDeliveryNoteButtonShown}
          isInvoiceButtonShown={isInvoiceButtonShown}
        />
      )}

      <Box
        sx={{
          borderTop: "1px solid #e0e0e0",
          padding: 2,
          backgroundColor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            value={inputMessage || ""}
            onChange={handleInputChange}
            placeholder="メッセージを入力"
            multiline
            minRows={3} // 高さを大きく
            maxRows={6}
            variant="outlined"
            sx={{ marginRight: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton component="label" disabled={isMutatePending}>
                    <AttachFile />
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                      accept="*"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={handleSendMessage}
            disabled={isMutatePending || (!inputMessage && !selectedFile)}
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "20px",
              paddingX: 3,
              paddingY: 1,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            送信する
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
