import { useGetImageFromS3 } from "../hooks/S3/useS3ImageHook";
import { Loading } from "./Loading";
import { Error } from "./Error";

export const Image = ({ filePath }: { filePath: string }) => {
  const { data, error, isLoading } = useGetImageFromS3(filePath);
  if (isLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <img
      alt={filePath}
      src={data || ""}
      style={{
        maxWidth: 200,
        maxHeight: 150,
        marginRight: 8,
        borderRadius: 4,
      }}
    />
  );
};
