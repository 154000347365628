import { OrderHistory } from "../../common/OrderHistory";
import { useGetWoods } from "../../../hooks/Wood/Wood/useWoodHook";
import { useCancelWoodOrder } from "../../../hooks/Wood/useWoodOrderHook";
import { woodColumnsForItem } from "../../../utils/columns";

export const WoodOrderHistory = () => {
  return (
    <OrderHistory<"wood">
      itemType="wood"
      useGetItems={useGetWoods}
      useCancelOrder={useCancelWoodOrder}
      columns={woodColumnsForItem}
    />
  );
};
