import { Box, IconButton, Typography } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import React from "react";
import { Loading } from "../../../../../uis/Loading";
import { Error } from "../../../../../uis/Error";
import { useGetImageFromS3 } from "../../../../../hooks/S3/useS3ImageHook";

interface ImageFormProps {
  selectedFile?: File;
  filePath: string | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const ImageForm: React.FC<ImageFormProps> = ({
  selectedFile,
  filePath,
  handleFileChange,
  disabled = false,
}) => {
  const {
    data: imageData,
    error: imageError,
    isLoading: imageLoading,
  } = useGetImageFromS3(filePath);
  const renderPreview = () => {
    if (!selectedFile && filePath) {
      if (imageLoading) {
        return <Loading />;
      }

      if (imageError) {
        return <Error message={imageError.message} />;
      }

      return (
        <Box
          component="img"
          src={imageData}
          alt="Selected"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      );
    } else if (selectedFile) {
      const fileType = selectedFile.type;

      if (fileType.startsWith("image/")) {
        return (
          <Box
            component="img"
            src={URL.createObjectURL(selectedFile)}
            alt="Selected"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        );
      } else {
        return (
          <Typography variant="body1" color="textSecondary">
            プレビューできないファイルタイプです。
          </Typography>
        );
      }
    } else {
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <IconButton color="primary" component="label">
            <PhotoCamera sx={{ fontSize: 50 }} />
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileChange}
            />
          </IconButton>
          <Typography>画像を選択</Typography>
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
        position: "relative",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
      }}
    >
      {!disabled && (
        <IconButton
          color="primary"
          component="label"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileChange}
          />
        </IconButton>
      )}
      {renderPreview()}
    </Box>
  );
};
