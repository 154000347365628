import { useMutation } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { User } from "./useUserHook";
import { TimeStamp } from "../utils/types/general_type";

export interface Stock extends TimeStamp {
  id: number;
  user_id: string;
  title?: string;
  content?: string;
  image_path?: string;
  is_temporarily_stored: boolean;
  is_published: boolean;
  created_at: Date;
  user: User;
}

interface StockResponse {
  message: string;
  type: number;
  stockId: number;
}

export function useCreateStock() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/stock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<Readonly<StockResponse>>;
    },
  });
}
