import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute";
import Sidebar from "../../uis/Bars/Sidebar";
import { Header } from "../../uis/Bars/Header";
import { Home } from "../../pages/Home/Home";
import { Document } from "../../pages/Home/Management/Document";
import { Order } from "../../pages/Home/Order";

export const ThreeSplitScreenLayout = () => {
  const location = useLocation();
  return (
    <PrivateRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={true} />
        <Box
          sx={{
            width: "80%",
          }}
        >
          <Header
            title={
              location.pathname === "/home"
                ? "ホーム"
                : location.pathname.includes("order")
                  ? "注文"
                  : "お知らせ"
            }
          />
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                width: "40%",
              }}
            >
              {location.pathname.includes("home") ? (
                <Home />
              ) : location.pathname.includes("order") ? (
                <Order />
              ) : (
                location.pathname.includes("/management/document") && (
                  <Document />
                )
              )}
            </Box>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </PrivateRoute>
  );
};
