import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { logMarketPriceKeys } from "../utils/query-key";
import { THIRTY_SECONDS } from "../utils/constant";
import {
  ReadonlyResponse,
  Status,
  TimeStamp,
} from "../utils/types/general_type";
import { toast } from "react-toastify";

export interface LogMarketPrice extends TimeStamp {
  id: number;
  file_path?: string;
  is_temporarily_stored: boolean;
  log_market_price_details: LogMarketPriceDetail[];
  log_market_price_status?: Status;
}

export interface LogMarketPriceDetail {
  origin: string;
  species: string;
  grade: string;
  top_diameter: number;
  length: number;
  unit_volume: number;
  quantity: number;
  market_price: number;
}

export type ReadonlyLogMarketPrice = Readonly<LogMarketPrice>;

export function useCreateLogMarketPrice() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/log_market_price`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
  });
}

export function useUpdateLogMarketPrice() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: LogMarketPrice) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/log_market_price`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(data),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res, variables) => {
      await queryClient.invalidateQueries({
        queryKey: logMarketPriceKeys.logMarketPrice,
      });
      if (variables.is_temporarily_stored) {
        toast.success("作成内容を一時保存しました");
      } else {
        toast.success("更新しました");
      }
    },
  });
}

export function useGetLogMarketPrice() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logMarketPriceKeys.logMarketPrice,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/log_market_price`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyLogMarketPrice>;
    },
    staleTime: THIRTY_SECONDS,
  });
}
