import { ProtectedRoute } from "./ProtectedRoute";
import { PrecutUsers } from "../utils/industry";

export const PrecutRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedRoute
      requiredIndustries={PrecutUsers}
      industryErrorMessage="木材に関する権限が存在しません。プレカット、製材所&プレカットのユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
