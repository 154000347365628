import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { BlackButton, WhiteButton } from "../../../../uis/Button/Button";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import {
  useGetWood,
  useUpdateWood,
  useSearchWoods,
} from "../../../../hooks/Wood/Wood/useWoodHook";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../../hooks/S3/useSignedURLHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { WoodTable } from "./wood_table";
import { ItemSearchBox } from "../../../../uis/Item/ItemSearchBox";
import { FileUpload } from "../../../../uis/File/FileUpload";
import { toast } from "react-toastify";
import { ReadonlyWood, Wood } from "../../../../hooks/Wood/Wood/type";

export const CreateWoodList = () => {
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [isPolling, setIsPolling] = useState<boolean>(false); // ポーリングの状態管理を追加
  const [data, setData] = useState<ReadonlyWood | null>(null); // dataを状態管理
  const { mutateAsync } = useUpdateWood();
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { wood_id } = useParams<{ wood_id: string }>();
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetWood({
    id: Number(wood_id),
    isOrdered: false,
    isTemporarilyStored: true,
  });
  const {
    data: filePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: data?.file_path,
  });
  const { data: woods, error: searchError } = useSearchWoods({
    searchText,
    id: Number(wood_id),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<Wood>();
  const navigate = useNavigate();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: "wood_details",
  });

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      if (fetchedData.wood_status?.status === "pending") {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    let intervalId: number; // NodeJS.Timeout から number に変更

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        // window.setInterval を使用
        try {
          const newData = await refetch(); // データを再取得
          if (newData && newData.data) {
            setData(newData.data); // dataを更新
            if (newData.data.wood_status?.status === "success") {
              setIsPolling(false); // ポーリングを停止
            } else if (newData.data.wood_status?.status === "failure") {
              toast.error(newData.data.wood_status.failure_reason);
              setIsPolling(false); // ポーリングを停止
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false); // エラー時もポーリングを停止
        }
      }, 1000); // 1秒ごとに実行
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      if (data.wood_details && data.wood_details.length > 0) {
        replace(data.wood_details);
      } else {
        replace([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSaveTemporarily = async (data: Wood) => {
    data.id = Number(wood_id);
    data.is_temporarily_stored = true;
    data.is_ordered = false;
    try {
      if (selectedFile) {
        const uploadData = {
          file: selectedFile,
          path: "excel_csv/wood",
        } as const satisfies SignedURLRequestToPut;
        const res = await mutateUploadFileToS3(uploadData);
        data.file_path = res;
        await mutateAsync(data);
        setIsPolling(true); // ポーリングを開始
      } else {
        await mutateAsync(data);
      }
      setSelectedFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: Wood) => {
    data.id = Number(wood_id);
    try {
      if (selectedFile) {
        const uploadData = {
          file: selectedFile,
          path: "excel_csv/wood",
        } as const satisfies SignedURLRequestToPut;
        const res = await mutateUploadFileToS3(uploadData);
        data.file_path = res;
        await mutateAsync(data);
        setIsPolling(true); // ポーリングを開始
      } else {
        await mutateAsync(data);
      }
      setSelectedFile(null);
      navigate(`/order/mailing/wood/${wood_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const watchedTitle = useWatch({ control, name: "title" });
  const watchedWoodDetails = useWatch({ control, name: "wood_details" });

  const areAllWoodDetailsComplete =
    watchedWoodDetails &&
    watchedWoodDetails.length > 0 &&
    watchedWoodDetails.every((woodDetail) =>
      Object.entries(woodDetail).every(
        ([key, value]) =>
          key === "id" || key === "amount" || (value !== "" && value !== 0),
      ),
    );

  const isWhiteButtonDisabled =
    !watchedTitle &&
    !selectedFile &&
    !(
      watchedWoodDetails &&
      watchedWoodDetails[0] &&
      Object.values(watchedWoodDetails[0]).some(
        (value) => value !== "" && value !== 0,
      )
    );

  const isBlackButtonDisabled = !watchedTitle || !areAllWoodDetailsComplete;

  if (isPending || getLoading) {
    return <Loading />;
  }

  if (error || getError || searchError) {
    return (
      <Error
        message={error?.message || getError?.message || searchError?.message}
      />
    );
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <ItemSearchBox
        searchText={searchText}
        setSearchText={setSearchText}
        searchClicked={searchClicked}
        setSearchClicked={setSearchClicked}
        items={woods}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) => navigate(`/order/create_wood_list/${id}`)}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <FileUpload
          selectedFile={selectedFile}
          filePath={filePath}
          handleFileChange={handleFileChange}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          message="CSV/Excelファイルを取りこむ"
        />
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title ? "タイトルを入力してください" : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
        />
        <WoodTable
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
        />
        <WhiteButton
          isSmallScreen
          text={"作成内容を一時保存"}
          onClick={handleSubmit(handleSaveTemporarily)}
          disabled={isWhiteButtonDisabled || isSubmitting}
        />
        <BlackButton
          isSmallScreen
          text={"送付先選択へ"}
          type="submit"
          disabled={isBlackButtonDisabled || isSubmitting}
        />
      </form>
    </Box>
  );
};
