import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import {
  ReadonlyResponseWithId,
  SearchRequest,
} from "../../utils/types/general_type";
import { LogOrder, LogOrders } from "../../hooks/Log/useLogOrderHook";
import { WoodOrder, WoodOrders } from "../../hooks/Wood/useWoodOrderHook";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { UserOrder } from "../../hooks/useUserHook";
import { FileDownload } from "../../uis/File/FileDownload";
import { toast } from "react-toastify";
import { UseMutationResult } from "@tanstack/react-query";
import {
  BlueprintOrder,
  BlueprintOrders,
} from "../../hooks/Blueprint/useBlueprintOrderHook";
import { ItemContractRequest, ItemType } from "../../utils/types/item_type";

interface OfferComponentProps<TData> {
  useSearchOffers: (params: SearchRequest) => {
    data: TData | undefined;
    error: Error | null;
  };
  useContractOrder: () => UseMutationResult<
    ReadonlyResponseWithId,
    Error,
    ItemContractRequest,
    unknown
  >;
}

function isLog(
  order: LogOrder | WoodOrder | BlueprintOrder,
): order is LogOrder {
  return (order as LogOrder).log !== undefined;
}

function isWood(
  order: LogOrder | WoodOrder | BlueprintOrder,
): order is WoodOrder {
  return (order as WoodOrder).wood !== undefined;
}

function isBlueprint(
  order: LogOrder | WoodOrder | BlueprintOrder,
): order is BlueprintOrder {
  return (order as BlueprintOrder).blueprint !== undefined;
}

export const OfferComponent = <
  TData extends LogOrders | WoodOrders | BlueprintOrders,
>({
  useSearchOffers,
  useContractOrder,
}: OfferComponentProps<TData>) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [order, setOrder] = useState<UserOrder | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error } = useSearchOffers({
    searchText,
    limit: 210,
  });
  const { mutateAsync, isPending } = useContractOrder();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleContract = async (
    orderId: number,
    type?: ItemType,
    itemId?: number,
  ) => {
    if (!itemId || !type) {
      toast.error("エラーが発生しました");
      return;
    }
    try {
      const res = await mutateAsync({ orderId, itemId });
      navigate(`/talk/${type}_order_message_room/${res.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <SearchBox
        placeholder="会社を検索"
        isSmallScreen={isSmallScreen}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.length > 0 &&
          data.map((order) => {
            const logOrder = isLog(order) ? order : undefined;
            const woodOrder = isWood(order) ? order : undefined;
            const blueprintOrder = isBlueprint(order) ? order : undefined;

            const totalAmount =
              logOrder && logOrder.log
                ? logOrder.log.total_amount
                : woodOrder && woodOrder.wood
                  ? woodOrder.wood.total_amount
                  : blueprintOrder && blueprintOrder.blueprint
                    ? blueprintOrder.blueprint.total_amount
                    : null;

            return (
              <ListItem key={order.user.id} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar user={order.user} isSmallScreen={isSmallScreen} />
                </ListItemAvatar>
                <ListItemText
                  primary={order.user.company_name}
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {woodOrder?.wood?.title ||
                          logOrder?.log?.title ||
                          blueprintOrder?.blueprint?.title ||
                          "タイトルが存在しません"}{" "}
                        {/* メッセージの内容 */}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        見積もり金額（合計）: ¥
                        {totalAmount && totalAmount !== null
                          ? totalAmount.toLocaleString()
                          : "データが存在しません"}
                      </Typography>

                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() =>
                          setOrder({
                            user: order.user,
                            wood: woodOrder?.wood,
                            log: logOrder?.log,
                            blueprint: blueprintOrder?.blueprint,
                          })
                        }
                      >
                        詳細を確認する
                      </Button>
                      <FileDownload
                        file={{
                          id:
                            logOrder && logOrder.log_quotation
                              ? logOrder.log_quotation.id
                              : woodOrder && woodOrder.wood_quotation
                                ? woodOrder.wood_quotation.id
                                : blueprintOrder &&
                                    blueprintOrder.blueprint_quotation
                                  ? blueprintOrder.blueprint_quotation.id
                                  : undefined,
                          itemType: logOrder
                            ? "log"
                            : woodOrder
                              ? "wood"
                              : blueprintOrder
                                ? "blueprint"
                                : undefined,
                        }}
                        category="quotation"
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() =>
                          handleContract(
                            order.id,
                            woodOrder
                              ? "wood"
                              : logOrder
                                ? "log"
                                : blueprintOrder
                                  ? "blueprint"
                                  : undefined,
                            woodOrder
                              ? woodOrder.wood.id
                              : logOrder
                                ? logOrder.log.id
                                : blueprintOrder
                                  ? blueprintOrder.blueprint.id
                                  : undefined,
                          )
                        }
                        disabled={isPending}
                      >
                        契約をする
                      </Button>
                    </Box>
                  }
                />
              </ListItem>
            );
          })}
      </List>
      {order !== null && (
        <OrderDetailModal
          order={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
