import { useGetDeliveryNotes } from "../../../../hooks/useDocumentHook";
import { DocumentList } from "../../../common/DocumentList";

export const DeliveryNoteList = () => {
  return (
    <DocumentList
      category="delivery_note"
      useGetDocuments={useGetDeliveryNotes}
    />
  );
};
